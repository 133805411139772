@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --bg-gray-50: rgb(249, 250, 251);
  --bg-gray-100: rgb(243, 244, 246);
  --bg-gray-200: rgb(229, 231, 235);
  --bg-gray-300: rgb(209, 213, 219);
  --bg-gray-400: rgb(156, 163, 175);
  --bg-gray-500: rgb(107, 114, 128);
  --bg-gray-600: rgb(75, 85, 99);
  --bg-gray-700: rgb(55, 65, 81);
  --bg-gray-800: rgb(31, 41, 55);
  --bg-gray-900: rgb(17, 24, 39);
  --bg-gray-950: rgb(3, 7, 18);
}

html {
  background-color: var(--bg-gray-50);
}

html, body {
  height: 100%; /* h-full */
}
